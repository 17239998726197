import styles from "./style.module.css"
const Map = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title_container}>
                <div className={styles.home_container}>
                    <div className={styles.section_container}>
                        <h3 className={styles.title}>Casa sposo</h3>
                        <span className={styles.title}>Via Saverio Altamura 2</span>
                    </div>
                    <div className={styles.section_container}>
                        <h3 className={styles.title}>Casa sposa</h3>
                        <span className={styles.title}>Via San Gennaro al vomero 15</span>
                    </div>
                </div>
                <div className={styles.section_container}>
                    <h3 className={styles.title}>Luoghi dell'evento</h3>
                    <ul>
                        <li className={styles.sub_title}>
                            <span className={styles.title}>Parrocchia SS. Antonio di Padova e Annibale Maria ore 16:00</span>
                        </li>
                        <li className={styles.sub_title}>
                            <span className={styles.title}>Tenuta Torelli ore 18:00</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.map_container}>
                <iframe title="locations" src="https://www.google.com/maps/d/u/1/embed?mid=1kYNRmft2PTuYwPSpt7A2L2DjSqVFIpQ&ehbc=2E312F" width="100%" height="100%"></iframe>
            </div>
        </div>
    )
}

export default Map