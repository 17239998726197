import styles from "./style.module.css"

const introduction = () => {
    return (
        <div className={styles.container}>
            <p className={styles.text}>
                Signore e signori, amiche e amici, ora si fa sul serio!
            </p>
            <p className={styles.text}>
                Chi ci conosce da tempo ha avuto modo di seguire i lunghi preparativi che ci hanno condotti a questo giorno, chi ci conosce da poco ne avrà sicuramente sentito parlare.
            </p>
            <p className={styles.text}>
                Ventotene, Napoli, Roma, Madrid, qui le tappe più importanti del nostro viaggio, il resto è storia!
            </p>
            <p className={styles.text}>
                Come avrete capito non ci piace molto stare fermi, amiamo essere dinamici e sempre in movimento.
                Ecco perché abbiamo preferito racchiudere in questo sito, accessibile da ogni parte d’Italia e d’Europa, un po’ di informazioni importanti per il nostro grande giorno, per dare a tutti voi la possibilità di seguirci senza troppa fatica e a noi quella di rendere questa giornata speciale anche per voi che ci avete accompagnato fin qui e che ci accompagnerete ancora a lungo - la strada è ancora tanta.
            </p>
            <p className={styles.text}>
                Ognuno di voi ha contribuito, consapevolmente o inconsapevolmente, al nostro percorso: per questo motivo siete gli unici in grado di rendere questo giorno ancora più nostro.
            </p>
            <img className={styles.image} src="andreaegretha.jpg" alt="andreaegretha"/>
        </div>
    )
}

export default introduction