import { useState } from 'react'
import styles from './style.module.css'
import { TrashIcon } from '../../assets/icons/trash'

const FormContainer = () => {
    const [data, setData] = useState<{
        confirm: boolean | null,
        yourName: string,
        guests: string[],
        allergy: string
    }>({
        confirm: null,
        yourName: '',
        guests: [],
        allergy: ''
    })
    const [guest, setGuest] = useState('')

    const mailNegative = `mailto:andreaegrethasposi@gmail.com?subject=Partecipazione%20matrimonio&body=Ciao%20sono%20${data.yourName},%20mi%20dispiace%20ma%20non%20potrò%20partecipare%20al%20vostro%20matrimonio`

    const mailYourName = data.yourName ? `mailto:andreaegrethasposi@gmail.com?subject=Partecipazione%20matrimonio&body=Ciao%20sono%20${data.yourName},%20parteciperò%20al%20vostro%20matrimonio` : ''

    const mailGuests = data.guests.length ? `%20con%20${data.guests.toString()}` : ''

    const mailAllergy = data.allergy ? `%20e%20non%20mangio%20${data.allergy}` : ''

    const mailTo = data.confirm ? mailYourName + mailGuests + mailAllergy : mailNegative

    return (
        <div className={styles.container}>
            <div className={styles.form_container}>
                <div className={styles.content_container}>
                    <h3 className={styles.title}>Partecipazione all'evento</h3>
                    <div className={styles.row_container}>
                        <button type='button' className={data.confirm ? styles.buttonYes : styles.button} onClick={() => { setData({ ...data, confirm: true }) }}>Si</button>
                        <button type='button' className={data.confirm === false ? styles.buttonNo : styles.button} onClick={() => { setData({ ...data, confirm: false }) }}>No</button>
                    </div>
                    <div className={styles.row_container}>
                        <input type="text" onChange={(e) => setData({ ...data, yourName: e.target.value })} className={styles.input} placeholder='Tuo nome' />
                    </div>
                </div>
                <div className={styles.content_container}>
                    <h3>Altri partecipanti</h3>
                    <div className={styles.row_container}>
                        <input type="text" value={guest} onChange={(e) => setGuest(e.target.value)} className={styles.input} placeholder='Nome' />
                        <button
                            className={styles.button}
                            disabled={!guest}
                            onClick={() => {
                                setData({ ...data, guests: [...data.guests, guest] })
                                setGuest('')
                            }}>
                            Aggiungi
                        </button>
                    </div>
                    {!!data.guests.length && data.guests.map((guest, index) => {
                        return <div key={guest + index} className={styles.row_container}>
                            <input type="text" className={styles.input} value={guest} readOnly />
                            <button
                                className={styles.trashButton}
                                onClick={() => {
                                    setData((state) => {
                                        const newState = { ...state }
                                        const filterGuests = newState.guests.filter((guest) => guest !== newState.guests[index])
                                        return { ...newState, guests: filterGuests }
                                    })
                                }}>
                                <TrashIcon />
                            </button>
                        </div>
                    })}
                </div>
                <div className={styles.content_container}>
                    <h3>Informazioni da segnalare</h3>
                    <div className={styles.row_container}>
                        <input type="text" onChange={(e) => setData({ ...data, allergy: e.target.value })} className={styles.input} placeholder='Allergie, intolleranze, vegetarianesimo...' />
                    </div>
                </div>
                <a href={mailTo} className={styles.submit_button}>
                    <span>Invia</span>
                </a>
            </div>
        </div>
    )
}

export default FormContainer