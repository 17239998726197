import styles from "./style.module.css"

const Iban = () => {
    return (
        <div className={styles.container}>
            <p className={styles.text}>
                Partecipando a questo giorno così importante avete già realizzato il nostro sogno.
            </p>
            <p className={styles.text}>
                Chiunque voglia contribuire alla realizzazione dei nostri progetti futuri può fare riferimento a:
            </p>
            <p className={styles.little_text}>
                IT86Y0301503200000003847955
            </p>
            <p className={styles.little_text}>
                Intestato a Perone Gretha e Cutolo Andrea
            </p>
        </div>
    )
}

export default Iban