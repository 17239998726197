import './App.css';
import FormContainer from './container/form/FormContainer';
import HomeContainer from './container/home/HomeContainer';
import Iban from './container/iban/Iban';
import Introduction from './container/introduction/introduction';
import Map from './container/map/Map';

function App() {
  return (
    <div className="App">
      <HomeContainer />
      <Introduction />
      <FormContainer />
      <Map />
      <Iban />
    </div>
  );
}

export default App;
